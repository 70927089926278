import dynamic from "next/dynamic"

export const icons = {
  leaf: dynamic(import("icons/Leaf")),
  recycle: dynamic(import("icons/Recycle")),
  silhouette: dynamic(import("icons/Silhouette")),
  sun: dynamic(import("icons/Sun")),
  co2: dynamic(import("icons/CO2")),
  solar: dynamic(import("icons/Solar")),
  wind: dynamic(import("icons/Wind")),
  biogas: dynamic(import("icons/Recycle")),
  arrow: dynamic(import("icons/ArrowRight")),
  brokenArrow: dynamic(import("icons/BrokenArrowRight")),
  smiley: dynamic(import("icons/Smiley")),
  send: dynamic(import("icons/Send")),
  creditCard: dynamic(import("icons/CreditCard")),
  bpay: dynamic(import("icons/Bpay")),
  dollarCircled: dynamic(import("icons/DollarCircled")),
  edit: dynamic(import("icons/Edit")),
  checkmark: dynamic(import("icons/Checkmark")),
  logout: dynamic(import("icons/Logout")),
  renewable: dynamic(import("icons/Renewable")),
  upload: dynamic(import("icons/Upload")),
}

export default icons
