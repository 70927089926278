import { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/router"

import useAuth from "lib/useAuth"

import LogoLockup from "icons/LogoLockup"
import HamburgerIcon from "icons/Hamburger"

import { HeaderEl } from "./styles"
import UserHeader from "./UserHeader"
import PublicHeader from "./PublicHeader"

export const Header = () => {
  const { user } = useAuth()
  const router = useRouter()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen((open) => !open)
  const close = () => setIsOpen(false)

  useEffect(() => {
    router.events.on("routeChangeComplete", close)
    return () => {
      router.events.off("routeChangeComplete", close)
    }
  }, [router.events])

  useEffect(() => {
    const escListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsOpen(false)
      }
    }

    document.body.addEventListener("keyup", escListener)

    return () => {
      document.body.removeEventListener("keyup", escListener)
    }
  }, [isOpen])

  return (
    <HeaderEl>
      <div className="flex justify-between items-center">
        <div className="pointer-events-auto">
          <Link href={user ? "/dashboard" : "/"} passHref>
            <a>
              <span className="sr-only">The People&apos;s Grid™</span>
              <LogoLockup className="hidden lg:block" aria-hidden="true" />
            </a>
          </Link>
          <button
            type="button"
            onClick={toggle}
            className="p-4 -ml-4 lg:hidden"
          >
            <span className="sr-only">Show Menu</span>
            <HamburgerIcon className="w-6 h-6" />
          </button>
        </div>
        {(user && <UserHeader isOpen={isOpen} toggle={toggle} />) || (
          <PublicHeader isOpen={isOpen} toggle={toggle} />
        )}
      </div>
    </HeaderEl>
  )
}

export default Header
