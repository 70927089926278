import classNames from "classnames"

import createStyle from "lib/createStyle"

export const themes = {
  primary: classNames(
    "rounded-full",
    "text-center px-5",
    "bg-green text-black",
    "active:bg-green",
    "disabled:text-disabled-dark disabled:bg-mercury",
    "shadow-xl lg:shadow-none",
    "hover:bg-spring hover:shadow-xl",
    "focus:bg-spring focus:shadow-xl focus:outline-none"
  ),
  secondary: classNames(
    "rounded-full",
    "text-center px-5",
    "bg-black text-white ",
    "hover:bg-smokey hover:shadow-xl",
    "focus:bg-smokey focus:shadow-xl focus:outline-none",
    "active:bg-black",
    "disabled:text-disabled-dark disabled:bg-mercury",
    "shadow-xl lg:shadow-none"
  ),
  subtle:
    "text-forest hover:underline disabled:hover:no-underline disabled:text-disabled-mid active:text-black",
  custom: "",
}

export const sizes = {
  large: "text-[1.125rem] leading-[1.2] py-5 min-w-[190px]",
  small: "text-[1rem] leading-[1.5] py-4 min-w-[155px]",
  xsmall: "text-[0.875rem] leading-[2] py-2 min-w-[80px]",
  custom: "",
}

export const ButtonBase = createStyle(
  "a",
  "font-medium inline-flex items-center transition duration-150 relative overflow-hidden group"
)
