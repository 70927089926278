import { SVGProps } from "react"

export const SmileyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.1 47.1" {...props}>
    <path
      d="M3.4,31.6c-1.1-2.9-1.6-5.9-1.5-8.9c0.2-5.2,2.3-10.4,6.3-14.4c8.5-8.5,22.2-8.5,30.6,0
	c8.5,8.5,8.5,22.2,0,30.6c-4,4-9.2,6.1-14.4,6.3c-3,0.1-6.1-0.4-8.9-1.5"
      stroke="currentColor"
      strokeWidth="3.8072"
      strokeLinecap="round"
      fill="none"
    />
    <g fill="currentColor">
      <path d="M11.7,24.2c0,3.2,1.3,6.3,3.6,8.6c2.3,2.3,5.4,3.6,8.6,3.6s6.3-1.3,8.6-3.6c2.3-2.3,3.6-5.4,3.6-8.6H23.8H11.7z" />
      <path d="M20.8,20.8c0-1.2-0.5-2.3-1.3-3.2c-0.8-0.8-2-1.3-3.2-1.3c-1.2,0-2.3,0.5-3.2,1.3c-0.8,0.8-1.3,2-1.3,3.2h3.8 c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5H20.8z" />
      <path d="M36.1,20.8c0-1.2-0.5-2.3-1.3-3.2c-0.8-0.8-2-1.3-3.2-1.3c-1.2,0-2.3,0.5-3.2,1.3c-0.8,0.8-1.3,2-1.3,3.2h3.8 c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5H36.1z" />
      <path d="M11.8,38.6c0,1.8-1.5,3.3-3.3,3.3s-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3S11.8,36.8,11.8,38.6z" />
    </g>
  </svg>
)

export default SmileyIcon
