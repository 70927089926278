import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import Head from "next/head"
import type { AppProps } from "next/app"
import dynamic from "next/dynamic"

import "styles/global.css"
import "styles/rte.css"
import "styles/fonts.css"

import UserLayout from "components/Layout"
import { AuthContextProvider, loginWithToken } from "context/AuthContext"
import { BreakpointContextProvider } from "context/BreakpointContext"
import Button from "components/Button"

const AdminUI = dynamic(import("components/builder/Layout"))

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const Layout = pageProps?.adminUI ? AdminUI : UserLayout
  const [user, setUser] = useState(pageProps?.user)

  useEffect(() => {
    setUser(pageProps?.user)
  }, [pageProps?.user])

  useEffect(() => {
    const getAdminCookie = async function (e) {
      if (e.origin !== process.env.NEXT_PUBLIC_API_URL) {
        return
      }
      setUser(await loginWithToken(e.data))
    }

    window.addEventListener("message", getAdminCookie)
    return () => {
      window.removeEventListener("message", getAdminCookie)
    }
  }, [user])

  useEffect(() => {
    const html = document.querySelector("html")

    function turnOffSmoothScroll() {
      html.style.scrollBehavior = "auto"
    }
    function turnOnSmoothScroll() {
      // Some browsers behave strangely without this RAF
      requestAnimationFrame(() => {
        html.style.scrollBehavior = "smooth"
      })
    }
    const pageView = () => {
      if (!window?.dataLayer) {
        return
      }
      window.dataLayer.push({ event: "pageview", page: router.asPath })
    }
    router.events.on("routeChangeComplete", pageView)

    router.events.on("routeChangeStart", turnOffSmoothScroll)
    router.events.on("routeChangeComplete", turnOnSmoothScroll)

    return () => {
      router.events.off("routeChangeComplete", pageView)
      router.events.off("routeChangeStart", turnOffSmoothScroll)
      router.events.off("routeChangeComplete", turnOnSmoothScroll)
    }
  }, [router.events, router.asPath])

  return (
    <>
      <Head>
        <title key="title">The People&apos;s Grid</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          key="description"
          content="The People's Grid creates a social energy network of customers, supporting local community energy
            by building collectives and connecting renewable energy producers directly to businesses and community groups.
            This energy marketplace is building a smarter energy system that is fairer, cleaner and cheaper for everyone. 
            Preparing for the next stage of decentralised variable energy, and delivering a more customer focused energy market."
        />
      </Head>
      <div className="sr-only focus-within:not-sr-only">
        <Button className="focus:fixed top-2 left-2 z-50" href="#main">
          Skip to content
        </Button>
      </div>
      <AuthContextProvider user={user}>
        <BreakpointContextProvider>
          <Layout collectiveData={pageProps?.collective}>
            <main id="main">
              <Component {...{ ...pageProps, user }} />
            </main>
          </Layout>
        </BreakpointContextProvider>
      </AuthContextProvider>
    </>
  )
}

export default MyApp
