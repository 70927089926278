import Link from "next/link"
import classNames from "classnames"

import createStyle from "lib/createStyle"

import Container from "components/Container"
import Grid from "components/Grid"
import { P } from "styles/Type"

// import LogoOxamiiLockupIcon from "icons/LogoOxamiiLockup"
import LogoCircularLockupIcon from "icons/LogoCircularLockup"

import { footerLinks } from "./data"

const FooterA = createStyle("a", "block py-3 lg:py-2 lg:hover:underline")

export const Footer = () => (
  <footer className="pt-20 lg:pt-28 pb-10 lg:pb-80 bg-off-white">
    <Container>
      <Grid>
        <div className="col-span-12 lg:col-span-3">
          <div className="mb-10">
            <LogoCircularLockupIcon className="max-w-full" height="60" />
          </div>
          <P
            as="address"
            className="not-italic mb-2 max-w-[250px] lg:max-w-none"
          >
            Innovation & Collaboration Centre <br />
            University of South Australia <br />
            Level 2, Cnr North Tce and Morphett St Bridge, Adelaide, SA, 5000
          </P>
        </div>
        <nav className="col-span-12 lg:col-start-7 lg:col-span-6 lg:row-span-2">
          <P as="ul" className="grid grid-cols-2 gap-2 lg:grid-cols-6">
            {footerLinks.map(({ label, links }, idx) => (
              <li
                key={label}
                className={classNames(
                  "col-span-2",
                  idx % 2 === 1 && "lg:col-start-4"
                )}
              >
                <hr className="mt-7 mb-8 h-px bg-black bg-opacity-20 lg:hidden" />
                <strong className="block mb-4 lg:mb-2">{label}</strong>
                {links && (
                  <ul className="grid gap-2 lg:gap-0 grid-cols-2 lg:grid-cols-1">
                    {links.map(
                      ({ label: childLabel, ...link }) =>
                        "href" in link && (
                          <li key={[label, childLabel].join("-")}>
                            <Link href={link.href} passHref>
                              <FooterA>{childLabel}</FooterA>
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </li>
            ))}
          </P>
        </nav>
        {/* <div className="col-span-12 lg:col-span-3 ">
          <P
            as="ul"
            className="mt-7 flex justify-center lg:justify-start items-baseline"
          >
            <li>
              <Link href="/" passHref>
                <FooterA>Terms &amp; Conditions</FooterA>
              </Link>
            </li> 
            <li aria-hidden="true">&nbsp;|&nbsp;</li>
            <li>
              <Link href="/privacy-policy" passHref>
                <FooterA>Privacy Policy</FooterA>
              </Link>
            </li>
          </P>
        </div> */}
      </Grid>
    </Container>
  </footer>
)

export default Footer
