import { SVGProps } from "react"

export const SilhouetteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="39"
    height="40"
    viewBox="0 0 39 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6133 11.0458C28.6133 16.1303 24.4934 20.2502 19.4088 20.2502C14.3243 20.2502 10.2043 16.1303 10.2043 11.0458C10.2043 5.96123 14.3243 1.84131 19.4088 1.84131C24.4934 1.84131 28.6133 5.96123 28.6133 11.0458Z"
    />
    <path d="M1 38.6592C1 28.4938 9.24352 20.2502 19.4089 20.2502C29.5743 20.2502 37.8178 28.4938 37.8178 38.6592" />
  </svg>
)

export default SilhouetteIcon
