import { createContext, useEffect, useState, useRef, FC, useMemo } from "react"

const breakpoints = {
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const BreakpointContext = createContext({
  isLarge: false,
  isMedium: false,
  isXLarge: false,
  vu: [0, 0],
})

export const BreakpointContextProvider: FC = ({ children }) => {
  const vuRef = useRef<HTMLDivElement>(null)

  const [isXLarge, setIsXLarge] = useState(true)
  const [isLarge, setIsLarge] = useState(true)
  const [isMedium, setIsMedium] = useState(true)
  const [vu, setVu] = useState([0, 0])

  useEffect(() => {
    let awaitingIdle = false
    const onResize = () => {
      awaitingIdle = false

      const isXLargeSize = window.matchMedia(`(min-width: ${breakpoints.xl}px)`)
      if (isXLargeSize.matches) {
        setIsXLarge(true)
      } else {
        setIsXLarge(false)
      }

      // Checking if window is large breakpoint
      const isLargeSize = window.matchMedia(`(min-width: ${breakpoints.lg}px)`)

      if (isLargeSize.matches) {
        setIsLarge(true)
      } else {
        setIsLarge(false)
      }

      // Checking if window is medium breakpoint
      const isMediumSize = window.matchMedia(`(min-width: ${breakpoints.md}px)`)

      if (isMediumSize.matches) {
        setIsMedium(true)
      } else {
        setIsMedium(false)
      }
      // get the size of 1vw and 1vh
      const vus = vuRef?.current?.getBoundingClientRect()
      if (!vus) {
        return
      }
      setVu([vus.width, vus.height])
    }
    onResize()
    const resizeCaller = () => {
      if (awaitingIdle) {
        return
      }
      awaitingIdle = true
      if (window?.requestIdleCallback) {
        requestIdleCallback(onResize)
      } else {
        setTimeout(onResize, 60)
      }
    }
    window.addEventListener("resize", resizeCaller)
    return () => {
      window.removeEventListener("resize", resizeCaller)
    }
  }, [])

  const fields = useMemo(
    () => ({ isXLarge, isLarge, isMedium, vu }),
    [isLarge, isMedium, isXLarge, vu]
  )

  return (
    <BreakpointContext.Provider value={fields}>
      {children}
      <div
        className="invisible w-[1vw] h-[1vh] absolute -top-10 pointer-events-none z-[-1] vu-ref"
        ref={vuRef}
      />
    </BreakpointContext.Provider>
  )
}

export default BreakpointContext
