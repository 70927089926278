import { forwardRef } from "react"
import classNames from "classnames"
import Link from "next/link"

import icons from "icons"
import BrokenArrowRightIcon from "icons/BrokenArrowRight"
import SmileyIcon from "icons/Smiley"

import { ButtonBase, themes, sizes } from "./styles"
import { ButtonProps } from "./types"

const ButtonEl = forwardRef<HTMLAnchorElement, ButtonProps>(
  (
    {
      className = "",
      label,
      theme = "primary",
      size = "small",
      children,
      icon,
      loading = false,
      ...props
    },
    ref
  ) => {
    const Icon = icons?.[icon] || (theme === "subtle" && BrokenArrowRightIcon)
    return (
      <ButtonBase
        {...props}
        className={classNames(
          className,
          themes[theme],
          sizes[size],
          loading && "pointer-events-none"
        )}
        ref={ref}
      >
        <div
          className={classNames(
            "flex w-full",
            "transition-opacity duration-300",
            "items-center",
            Icon ? "justify-between" : "justify-center",
            loading && "invisible opacity-0",
            !loading && "delay-500"
          )}
          aria-hidden={loading ? "true" : "false"}
        >
          {label || children}
          {Icon && (
            <Icon
              className={classNames(
                "w-5 h-5",
                "transition duration-500",
                "group-hover:-translate-x-3",
                theme === "subtle" && "ml-3",
                theme !== "subtle" && "ml-11",
                "mr-1"
              )}
              aria-hidden="true"
            />
          )}
        </div>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {loading && <span className="sr-only">Loading&ellip;</span>}
          <div
            aria-hidden="true"
            className={classNames(
              "duration-500",
              loading ? "transition-opacity" : "transition",
              "ease-bounce-out",
              !loading && "-translate-y-16 opacity-0"
            )}
          >
            <SmileyIcon
              className={classNames(
                "w-5 h-5",
                loading && "animate-chunky-spin"
              )}
            />
          </div>
        </div>
      </ButtonBase>
    )
  }
)

export const Button = ({ href, ...props }: ButtonProps) => {
  if (href?.slice(0, 1) === "/") {
    return (
      <Link href={href} passHref>
        <ButtonEl {...props} />
      </Link>
    )
  }
  const isHash = href?.slice(0, 1) === "#"
  return (
    <ButtonEl
      href={href}
      target={href && !isHash ? "_blank" : undefined}
      rel={href && !isHash ? "noopener" : undefined}
      {...props}
    />
  )
}

export default Button
