import createStyle from "lib/createStyle"

export const Title = createStyle(
  "h1",
  "font-headline font-medium text-[2.8125rem] lg:text-[5rem] tracking-[-0.02em] leading-[1.1] lg:leading-[0.95]" // 80px
)

export const H1 = createStyle(
  "h1",
  "font-headline font-normal text-[2.0625rem] lg:text-[3rem] tracking-[-0.03em] leading-[1.1]" // 48px
)

export const H2 = createStyle(
  "h2",
  "font-headline font-normal text-[1.625rem] lg:text-[2.25rem] tracking-[-0.02em] leading-[1.2]" // 36px
)

export const H3 = createStyle(
  "h3",
  "text-[1.1875rem] lg:text-[1.5rem] tracking-[-0.02em] leading-[1.2] font-medium" // 24px
)

export const H4 = createStyle(
  "h4",
  "text-[1.0625rem] lg:text-[1.25rem] tracking-[-0.01em] leading-[1.2] font-medium" // 20px
)

export const H5 = createStyle(
  "h5",
  "text-[0.8125rem] tracking-[0.05em] leading-[1.2] font-medium uppercase" // 13px
)

export const Lead = createStyle(
  "p",
  "text-[1.0625rem] lg:text-[1.25rem] tracking-[-0.02em] leading-[1.5]" // 20px
)

export const P = createStyle(
  "p",
  "text-[0.9375rem] lg:text-[1.125rem] tracking-[-0.02em] leading-[1.5]" // 18px
)

export const Small = createStyle(
  "p",
  "text-[0.75rem] lg:text-[0.875rem] leading-[1.3]" // 14px
)
