import createStyle from "lib/createStyle"

export const HeaderEl = createStyle(
  "header",
  "pt-4 lg:pt-0 px-5 xl:px-10 lg:bg-black lg:text-white fixed top-0 w-full z-40 pointer-events-none md:pointer-events-auto"
)

export const NavA = createStyle(
  "a",
  "text-[1.125rem] lg:text-[1rem] lg:tracking-[-0.02em] font-normal flex items-center justify-between border-b border-off-white border-opacity-40 lg:border-none py-10 lg:py-9 leading-[0.95] tracking-[-0.02em] lg:hover:underline"
)

export const SubmenuA = createStyle(
  "a",
  "py-4 lg:py-2.5 lg:px-3 font-medium lg:font-light flex lg:focus:bg-off-white lg:focus:text-forest lg:hover:bg-off-white lg:hover:text-forest transition duration-200"
)
