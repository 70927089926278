import { FooterLink } from "./types"

export const footerLinks: FooterLink[] = [
  {
    label: "Legal Stuff",
    links: [
      {
        label: "Terms & Conditions",
        href: "/terms",
      },
      {
        label: "Privacy Policy",
        href: "/privacy-policy",
      },
      {
        label: "Market Retail Contract",
        href: "/market-retail-contract",
      },
      {
        label: "Basic Plan & Fact Sheets",
        href: "/basic-plans",
      },
    ],
  },
  {
    label: "Need Help?",
    links: [
      {
        label: "FAQs & Support",
        href: "/support",
      },
      {
        label: "Contact Us",
        href: "/support#contact",
      },
    ],
  },
]

export default footerLinks
