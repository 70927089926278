import { useState, useRef, useEffect } from "react"
import NextImage, { ImageLoader } from "next/image"
import classNames from "classnames"

import { ImageProps } from "./types"

const CloudFrontUrl =
  process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGE_URL ||
  "https://cdn.thepeoplesgrid.com"

// https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/deployment.html
export const lambdaLoader: ImageLoader = ({ src, width, quality }) => {
  const [, bucket] = src.match(/https:\/\/([\w-]+)/)
  const [, key] = src.match(/amazonaws\.com\/(.*)/)
  const imageReq = JSON.stringify({
    bucket,
    key,
    edits: {
      resize: {
        width,
      },
    },
    quality,
  })

  const rq =
    typeof window === "undefined"
      ? Buffer.from(imageReq).toString("base64")
      : btoa(imageReq)
  return `${CloudFrontUrl}/${rq}`
}

export const Image = ({
  className,
  ...rest
}: ImageProps & { image_src?: string; alt_text?: string }) => {
  const [loaded, setLoaded] = useState(false)
  const imageWrapper = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width: _width, height: _height, ...lessDims } = rest
  const props = rest?.layout === "fill" ? lessDims : rest

  const src =
    ("src" in props && props.src) || ("image_src" in props && props.image_src)
  const alt =
    ("alt" in props && props.alt) ||
    ("alt_text" in props && props.alt_text) ||
    ""
  delete props.image_src
  delete props.alt_text

  // Handle images that load before hydration
  useEffect(() => {
    if (
      imageWrapper.current?.querySelector<HTMLImageElement>(
        "img:not([aria-hidden])"
      )?.complete
    ) {
      setLoaded(true)
    }
  }, [])

  if (!src) {
    return (
      <div ref={imageWrapper} className="w-full">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt=""
          className={classNames(className, "block invisible max-w-full")}
          {...props}
          src=""
        />
      </div>
    )
  }

  const newProps: {
    src: any
    alt?: string
    onLoad: () => void
    loader?: ImageLoader
    unoptimized: boolean
  } = {
    src,
    alt,
    onLoad() {
      setLoaded(true)
    },
    unoptimized: false,
  }

  if (src?.match(/^https:\/\/.+\.s3/)) {
    newProps.loader = lambdaLoader
  } else {
    newProps.unoptimized = true
  }
  return (
    <div
      ref={imageWrapper}
      className={classNames(
        props.layout === "responsive" ? "block" : "flex",
        props.layout === "fill" && "absolute inset-0 w-full h-full"
      )}
    >
      <NextImage
        {...newProps}
        objectFit="cover"
        className={classNames(
          className,
          !loaded && "opacity-0",
          "transition-opacity duration-300"
        )}
        {...props}
      />
    </div>
  )
}

export default Image
