import Cookies from "js-cookie"

export const AUTH_COOKIE_PATH = "Authorization"

export const API_URL =
  process.env.NEXT_PUBLIC_API_URL || "https://staging-admin.thepeoplesgrid.com"

type Message = {
  [propName: string]: any
}

type ErrorMessage =
  | {
      error: string
      message: {
        messages: {
          message: string
        }[]
      }[]
    }
  | {
      error: string
      message: string
    }
  | {
      errors: {
        message: string
      }[]
    }

const processResponse = async (r: Response) => {
  const response = r

  const data: ErrorMessage | Message = await r.json()

  if (!response.ok) {
    let message = "Unknown error"

    if ("error" in data) {
      if (Array.isArray(data?.message)) {
        message = data?.message
          ?.reduce(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (messages: string[], mss: { messages: { message: string }[] }) => [
              ...messages,
              ...mss.messages.map((m) => m.message),
            ],
            []
          )
          ?.join(", ")
      } else {
        message = data.message
      }
    } else if ("non_field_errors" in data) {
      message = data.non_field_errors
    } else if ("detail" in data) {
      message = data.detail
    } else if (data?.errors) {
      message = data?.errors
        ?.map(({ message: dataMessage }: { message: string }) => dataMessage)
        ?.join("\n")
    } else if (response?.statusText) {
      message = response?.statusText
    }
    throw {
      status: r.status,
      message:
        ("error" in data && [data.error, message].join(" - ")) ||
        message ||
        response?.statusText ||
        "Unknown error",
      data,
    }
  }
  return {
    data,
    response,
  } as {
    data: Message
    response: Response
  }
}

function clearToken() {
  Cookies.remove(AUTH_COOKIE_PATH)
}

function getAuthToken() {
  if (typeof window !== "undefined") {
    const token = Cookies.get(AUTH_COOKIE_PATH)
    return token ? `Token ${token}` : null
  }
  return null
}

export const API = {
  headers: {
    Authorization: getAuthToken(),
  },
  async get(route: string) {
    const getData = fetch(`${API_URL}${route}`, {
      headers: this.headers,
    })
    return getData
      .then(processResponse)
      .catch((err) => {
        if (err.message?.includes("Invalid token")) {
          clearToken()
          this.stripToken()
          return fetch(`${API_URL}${route}`).then(processResponse)
        }
        return err
      })
      .catch((err) => {
        console.log(err)
        return { data: null, response: null }
      })
  },
  async modify(
    method = "POST",
    route: string,
    data: { [propName: string]: any } | FormData = {},
    headers = {}
  ) {
    if (typeof window !== "undefined" && data instanceof FormData) {
      delete this.headers["Content-Type"]
    } else {
      this.headers["Content-Type"] = "application/json"
    }
    return fetch(`${API_URL}${route}`, {
      method,
      headers: {
        ...this.headers,
        ...headers,
      },
      body:
        typeof window !== "undefined" && data instanceof FormData
          ? data
          : JSON.stringify(data),
    }).then(processResponse)
  },
  async post(
    route: string,
    data: { [propName: string]: any } = {},
    headers = {}
  ) {
    return this.modify("POST", route, data, headers)
  },
  async put(route: string, data: { [propName: string]: any }) {
    return this.modify("PUT", route, data)
  },
  async patch(route: string, data: { [propName: string]: any }) {
    return this.modify("PATCH", route, data)
  },
  async delete(route: string) {
    return this.modify("DELETE", route)
  },
  setToken(key) {
    this.headers.Authorization = `Token ${key}`
    return this
  },
  stripToken() {
    delete this.headers.Authorization
    return this
  },
}

export default API
