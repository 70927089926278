import { NavLink } from "./types"

export const publicNavLinks: NavLink[] = [
  {
    label: "Collectives",
    childLinks: [
      {
        label: "Goodwood",
        href: "/collectives/goodwood",
      },
      {
        label: "Aaron's Renewable Supporters Group",
        href: "/collectives/friends-of-oxamii",
      },
      {
        label: "Friends of Maximum (SA)",
        href: "/collectives/friends-of-maximum-sa",
      },
      // {
      //   label: "Mount Barker",
      //   href: "/collectives/mount-barker-hills-community",
      // },
      // {
      //   label: "Sandy Point",
      //   href: "/collectives/sandy-point-community-power",
      // },
      // {
      //   label: "Thomas Plains Tennis",
      //   href: "/collectives/thomas-plains-tennis-club",
      // },
    ],
  },
  { label: "About", href: "/about" },
  { label: "How it works", href: "/how-it-works" },
  { label: "Support", href: "/support" },
]

export const privateNavLinks: NavLink[] = []
