import { useState, useCallback, useEffect } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import classNames from "classnames"
import { captureException } from "@sentry/nextjs"

import useAuth from "lib/useAuth"
import Image from "components/Image"
import { Lead } from "styles/Type"

import LogoDeviceIcon from "icons/LogoDevice"
import CloseIcon from "icons/Close"
import BrokenArrowRightIcon from "icons/BrokenArrowRight"
import SilhouetteIcon from "icons/Silhouette"
import ChevronDownIcon from "icons/ChevronDown"
import LogoutIcon from "icons/Logout"

import { NavA, SubmenuA } from "./styles"

export const UserHeader = ({ isOpen, toggle }) => {
  const { logout, user } = useAuth()
  const router = useRouter()
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)

  useEffect(() => {
    const closeSubmenuOnRoute = () => {
      setIsSubmenuOpen(false)
    }
    router.events.on("routeChangeComplete", closeSubmenuOnRoute)
    return () => {
      router.events.off("routeChangeComplete", closeSubmenuOnRoute)
    }
  }, [router.events])

  const doLogout = useCallback(async () => {
    router.prefetch("/login")
    try {
      await logout()
    } catch (e) {
      captureException({
        "@components/Header/UserHeader/doLogout.fn": "location",
        error: JSON.stringify(e),
      })
      console.error(e)
    }
    router.reload()
  }, [router, logout])

  return (
    <div>
      <nav
        className={classNames(
          "transition-all duration-300 transform",
          !isOpen && "invisible -translate-x-full",
          "lg:visible lg:translate-x-0",
          "fixed z-30 inset-0 lg:static pb-6 lg:pb-0 overflow-auto lg:overflow-visible",
          "flex items-center",
          "flex-col lg:flex-row justify-between items-stretch",
          "bg-black text-white",
          "pointer-events-auto"
        )}
      >
        <div className="flex justify-between items-start lg:hidden px-5 mt-8 mb-14">
          <Link href="/" passHref>
            <a>
              <LogoDeviceIcon className="w-14 h-16" />
            </a>
          </Link>
          <button type="button" onClick={toggle} className="p-4 -mt-4 -mr-4">
            <CloseIcon className="block" />
          </button>
        </div>
        <ul className="flex flex-col lg:flex-row">
          <li className="px-5">
            <Link href="/dashboard" passHref>
              <NavA>
                Dashboard <BrokenArrowRightIcon className="lg:hidden" />
              </NavA>
            </Link>
          </li>
          <li className="px-5">
            <Link href="/billing" passHref>
              <NavA>
                Billing <BrokenArrowRightIcon className="lg:hidden" />
              </NavA>
            </Link>
          </li>
          <li className="flex relative items-center ml-2.5">
            <Link href="/account" passHref>
              <a className="w-9 h-9 rounded-full bg-white text-black items-center justify-center overflow-hidden hidden lg:flex relative">
                <span className="sr-only">Account</span>
                {user.profile_image && (
                  <Image src={user.profile_image} width="36" height="36" />
                )}
                {!user.profile_image && <SilhouetteIcon className="w-5 h-5" />}
              </a>
            </Link>
            <button
              type="button"
              className={classNames(
                "hidden lg:inline-block p-4 -mr-4",
                isSubmenuOpen && "rotate-180",
                "transition-transform duration-200 ease-bounce-in"
              )}
              onClick={() => setIsSubmenuOpen((o) => !o)}
              aria-haspopup="true"
              aria-expanded={isSubmenuOpen ? "true" : "false"}
              aria-controls="profile-submenu"
            >
              <ChevronDownIcon />
            </button>
            <Lead
              as="ul"
              className={classNames(
                "lg:absolute lg:right-0 lg:bg-white lg:text-black top-[80%] w-full lg:w-[250px] rounded-md lg:border border-disabled-light mt-5 lg:mt-0",
                !isSubmenuOpen &&
                  "lg:invisible lg:pointer-events-none lg:opacity-0 lg:-translate-y-4",
                "transition-all duration-200 ease-bounce-in",
                "overflow-hidden"
              )}
              id="profile-submenu"
            >
              <li>
                <Link href="/account" passHref>
                  <SubmenuA>Account Details</SubmenuA>
                </Link>
              </li>
              <li>
                <SubmenuA
                  as="button"
                  type="button"
                  className="justify-between items-center w-full"
                  onClick={doLogout}
                >
                  Log out
                  <LogoutIcon className="w-6 h-6" />
                </SubmenuA>
              </li>
            </Lead>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default UserHeader
