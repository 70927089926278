import { ReactNode } from "react"
import dynamic from "next/dynamic"

import useAuth from "lib/useAuth"

import Header from "components/Header"
import Footer from "components/Footer"

const AdminUI = dynamic(import("components/builder/Layout"))

export const Layout = ({
  children,
  collectiveData,
}: {
  children: ReactNode
  collectiveData?: any
}) => {
  const { user } = useAuth()

  if (user?.collective_admin_of?.length > 0) {
    return <AdminUI collectiveData={collectiveData}>{children}</AdminUI>
  }

  return (
    <>
      <Header />
      <div className="h-20 hidden lg:block" />
      {children}
      <Footer />
    </>
  )
}

export default Layout
