/* eslint-disable import/no-cycle */
import {
  useEffect,
  useCallback,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react"
import { useRouter } from "next/router"
import Cookies from "js-cookie"
import { configureScope, setUser as setSentryUser } from "@sentry/nextjs"

import { API, AUTH_COOKIE_PATH } from "lib/api"

export type Account = {
  account_id: number,
  name: string,
  account_type: string,
  status: string,
  abn: string,
  customer_billing_address: {
    country: string
    line_1: string
    line_2: string
    postcode: string
    state: string
    suburb: string
  }
}

export type User = {
  first_name: string
  last_name: string
  profile_image?: string
  email: string
  created_on: string
  date_of_birth: string
  phone_number: string
  salutation: string
  username: string
  is_collective_admin: boolean
  collective_admin_of?: { id: number; slug: string; name: string }[]
  account: Account
}

const ctxSetState: Dispatch<SetStateAction<User>> = (s) => s

export const AuthContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  user: null as User,
  setUser: ctxSetState,
  login: async (form) => ({}),
  logout: async () => {},
  loginWithToken: async (token: string) => ({}),
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const loginWithToken = async (key) => {
  Cookies.set(AUTH_COOKIE_PATH, key, { expires: 1, secure: true, sameSite: "strict"});
  API.setToken(key)
  const { data } = await API.get("/rest-auth/user/")
  return data
}

export const AuthContextProvider = ({ user: _user, children }) => {
  const [user, setUser] = useState(_user)
  const router = useRouter()

  const validateToken = useCallback(async () => {
    const { data } = await API.get("/rest-auth/user/")
    return data
  }, [])

  useEffect(() => {
    setUser(_user)
  }, [_user])

  useEffect(() => {
    if (!user) {
      configureScope((scope) => scope.setUser(null))
      return
    }
    setSentryUser({
      email: user.email,
      username: user.username,
    })
  }, [user])

  useEffect(() => {
    if (!Cookies.get(AUTH_COOKIE_PATH)) {
      return
    }
    validateToken()
      .then((u) => {
        setUser(u)
      })
      .catch(() => {
        console.info("ℹ️ Cleared expired Auth cookie")
        Cookies.remove(AUTH_COOKIE_PATH)
        API.headers.Authorization = undefined
      })
  }, [validateToken])

  const loginWithTokenCallback = useCallback(async (key) => {
    var data = await loginWithToken(key)
    setUser(data)
    return data
  }, [])

  const login = useCallback(async (form) => {
    Cookies.remove(AUTH_COOKIE_PATH)
    const {
      data: { key },
    } = await API.post("/rest-auth/login/", form)
    const { data } = await loginWithToken(key)
    return data
  }, [loginWithToken])

  const logout = useCallback(async () => {
    try {
      await API.post("/rest-auth/logout/")
    } catch (e) {
      console.warn("Error logging out", e)
    }
    Cookies.remove(AUTH_COOKIE_PATH)
    API.headers.Authorization = undefined
    // Wait until the new page has loaded before unsetting the user in case the page relies on user data
    function resetUser() {
      setUser(null)
      router.events.off("routeChangeComplete", this)
    }
    router.events.on("routeChangeComplete", resetUser)
  }, [router.events])

  const fields = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      loginWithToken: loginWithTokenCallback,
    }),
    [login, loginWithTokenCallback, logout, user]
  )

  return <AuthContext.Provider value={fields}>{children}</AuthContext.Provider>
}
export default AuthContext
