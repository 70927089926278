import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import classNames from "classnames"

import Button from "components/Button"

import { Lead } from "styles/Type"
import LogoDeviceIcon from "icons/LogoDevice"
import CloseIcon from "icons/Close"
import BrokenArrowRightIcon from "icons/BrokenArrowRight"
import ChevronDownIcon from "icons/ChevronDown"

import { NavA, SubmenuA } from "./styles"
import { publicNavLinks } from "./data"

export const PublicHeader = ({ isOpen, toggle }) => {
  const router = useRouter()

  const [submenuOpen, setSubmenuOpen] = useState(false)
  const [publicCollectives, setPublicCollectives] = useState([])

  // TODO: This should be done SSG and then passed down
  useEffect(() => {
    import("lib/fetchPublicCollectives").then(
      ({ default: fetchPublicCollectives }) => {
        fetchPublicCollectives().then(setPublicCollectives)
      }
    )
  }, [])

  return (
    <div>
      <Button
        theme="secondary"
        size="custom"
        className={classNames(
          "text-[0.875rem] px-4 leading-[1.7] py-2 min-w-[110px] lg:hidden pointer-events-auto",
          router.pathname === "/login" && "hidden"
        )}
        href="/login"
      >
        Login
      </Button>
      <nav
        className={classNames(
          "transition-all duration-300 transform",
          !isOpen && "invisible -translate-x-full",
          "lg:visible lg:translate-x-0",
          "fixed z-30 inset-0 lg:static pb-6 lg:pb-0 overflow-auto lg:overflow-visible",
          "flex items-center",
          "flex-col lg:flex-row justify-between items-stretch",
          "bg-black text-white",
          "pointer-events-auto"
        )}
      >
        <div className="flex justify-between items-start lg:hidden px-5 mt-8 mb-14">
          <Link href="/" passHref>
            <a>
              <LogoDeviceIcon className="w-14 h-16" />
            </a>
          </Link>
          <button type="button" onClick={toggle} className="p-4 -mt-4 -mr-4">
            <CloseIcon className="block" />
          </button>
        </div>
        <ul className="flex flex-col lg:flex-row">
          <li className="px-5 lg:hidden">
            <Link href="/" passHref>
              <NavA>
                Home <BrokenArrowRightIcon className="lg:hidden" />
              </NavA>
            </Link>
          </li>
          {publicNavLinks.map(({ label, ...rest }, idx) => (
            <li
              key={label}
              className={classNames(
                "px-5 lg:px-4 relative",
                submenuOpen && " group"
              )}
              tabIndex={"childLinks" in rest ? 0 : undefined}
            >
              {"href" in rest && (
                <Link href={rest.href} passHref>
                  <NavA
                    className={classNames(
                      idx === publicNavLinks.length - 1 && "border-none"
                    )}
                  >
                    {label}
                    <BrokenArrowRightIcon className="lg:hidden" />
                  </NavA>
                </Link>
              )}
              {"childLinks" in rest && (
                <NavA
                  as="div"
                  onClick={() => setSubmenuOpen((o) => !o)}
                  className={classNames(submenuOpen && "border-none")}
                >
                  {label}{" "}
                  <ChevronDownIcon
                    className={classNames("inline-block -my-1 ml-1")}
                  />
                </NavA>
              )}
              {"childLinks" in rest && (
                <Lead
                  as="ul"
                  className={classNames(
                    "lg:absolute lg:right-0 lg:bg-white lg:text-black top-[80%] w-full lg:w-[250px] lg:rounded-md lg:border border-disabled-light",
                    "lg:invisible lg:pointer-events-none lg:opacity-0 lg:-translate-y-4",
                    "lg:group-focus-within:visible lg:group-focus-within:pointer-events-auto lg:group-focus-within:opacity-100 lg:group-focus-within:translate-y-0",
                    "lg:group-hover:visible lg:group-hover:pointer-events-auto lg:group-hover:opacity-100 lg:group-hover:translate-y-0",
                    "transition-all duration-200 ease-bounce-in",
                    "max-h-[40rem] overflow-y-auto",
                    "pb-7 lg:pb-0 -mt-4 lg:mt-0",
                    !submenuOpen && "hidden lg:block",
                    "border-b border-opacity-40 border-off-white lg:border-opacity-100"
                  )}
                >
                  {(label === "Collectives"
                    ? publicCollectives
                    : rest.childLinks
                  ).map(({ label: childLabel, href }) => (
                    <li
                      key={[childLabel, href].join("_")}
                      onClick={() => setSubmenuOpen(false)}
                    >
                      <Link href={href} passHref>
                        <SubmenuA>
                          {childLabel}
                          <BrokenArrowRightIcon className="text-green lg:hidden ml-5" />
                        </SubmenuA>
                      </Link>
                    </li>
                  ))}
                </Lead>
              )}
            </li>
          ))}
        </ul>
        <div
          className={classNames(
            "flex lg:ml-6 flex-col lg:items-center lg:flex-row px-5 lg:p-0",
            router.pathname === "/login" && "hidden"
          )}
        >
          <Button
            theme="custom"
            size="custom"
            className={classNames(
              "rounded-full",
              "bg-white hover:bg-green text-black border border-white",
              "px-4 text-center py-4 lg:py-2 min-w-[110px]",
              "lg:ml-5 my-4 lg:my-[22px]",
              "lg:text-[0.875rem] leading-[1.7]"
            )}
            href="/login"
          >
            Login
          </Button>
        </div>
      </nav>
    </div>
  )
}

export default PublicHeader
